<template>
  <div class="course-time">
    <div class="inner">
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="type-bar">
        <div class="t-list">
          <div class="t-type">状态:</div>
          <div
            :class="courseStateIndex === index ? 't-item-active' : 't-item'"
            v-for="(item, index) in courseState"
            :key="index"
            @click="changeCourseState(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="t-list">
          <div class="t-type-40">指定日期：</div>
          <el-date-picker
            class="date-picker"
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="onfirmDate"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
      </div>
      <div class="c-handle">
        <div class="tab-bar">
          <div class="t-tab">
            <div
              :class="['t-item', { 't-current': index === tabIndex }]"
              v-for="(item, index) in tabList"
              :key="index"
              @click="changeTab(index)"
            >
              {{ item }}
              <div class="t-line"></div>
            </div>
          </div>
        </div>
        <div class="c-wrap">
          <el-select
            v-show="tabIndex == 1"
            clearable
            v-model="courseId"
            placeholder="请选择一对一课程列表"
            @change="onChooseCourse"
          >
            <el-option
              v-for="item in o2oCourses"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <div class="search-bar ml20" v-show="tabIndex == 0">
            <input
              class="s-input nohighlight"
              style="border:none;"
              type="text"
              placeholder="请输入课程名称或学生姓名"
              v-model.trim="keywords"
              @keyup.enter="onSearch"
              @onkeyup="this.value = this.value.replace(/^ +| +$/g, '')"
            />
            <span class="s-lens" @click="onSearch"></span>
          </div>
        </div>
      </div>
      <div class="t-table" v-show="tabIndex === 0">
        <el-table class="table" stripe :data="aldyApptList" style="width: 100%">
          <el-table-column
            prop="lessonName"
            label="课次名称"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="上课日期" width="220">
            <template slot-scope="scope">
              <p>{{ scope.row.lessonDate }}-{{ scope.row.startTime }}-{{ scope.row.endTime }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="课程状态" width="120">
            <template slot-scope="scope">
              <p>{{ courseConfig[scope.row.status] }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="studentName" label="学生姓名" width="120"></el-table-column>
          <el-table-column prop="studentNickName" label="学生昵称" width="120"></el-table-column>
          <el-table-column prop="studentMobileNo" label="学生手机号" width="140"></el-table-column>
          <el-table-column label="操作" width="300">
            <template slot-scope="scope">
              <el-button size="mini" type="danger" @click="showCancelAppt(scope.row)">
                取消预约
              </el-button>
              <el-button size="mini" @click="absentAppt(scope.row, 't')">老师旷课</el-button>
              <el-button size="mini" type="warning" @click="absentAppt(scope.row, 's')">
                学生旷课
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="t-time" v-show="tabIndex === 1">
        <span>请选择日期：</span>
        <el-date-picker
          v-model="apptDate"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期时间"
          @change="onfirmApptDate"
        ></el-date-picker>
        <!--     <div class="t-pre"></div>
        <div class="t-next"></div>
        <el-tabs class="t-timeline" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="3月12日" name="first"></el-tab-pane>
        </el-tabs> -->
        <div class="t-form">
          <div class="t-buttons">
            <span
              :class="{ 't-current': index === buttonIndex }"
              v-for="(item, index) in buttonItems"
              :key="index"
              @click="changeButton(index)"
            >
              {{ item }}
            </span>
          </div>
          <!-- 可预约课程列表 -->
          <div class="wrap" v-show="buttonIndex == 0">
            <div class="wrap-column">
              <span>上午</span>
              <div class="t-times">
                <span v-for="(item, index) in notAppt.forenoonList" :key="index">
                  {{ item.hour }}:00
                </span>
              </div>
            </div>
            <div class="wrap-column">
              <span>下午</span>
              <div class="t-times">
                <span v-for="(item, index) in notAppt.afternoonList" :key="index">
                  {{ item.hour }}:00
                </span>
              </div>
            </div>
            <div class="wrap-column">
              <span>晚上</span>
              <div class="t-times">
                <span v-for="(item, index) in notAppt.nightList" :key="index">
                  {{ item.hour }}:00
                </span>
              </div>
            </div>
            <!--   <div class="wrap-btn">
              <div class="cancel-btn">取消</div>
              <div class="confirm-btn">保存</div>
            </div> -->
          </div>
          <!-- 已预约课程列表 -->
          <div class="wrap" v-show="buttonIndex == 1">
            <div class="wrap-column">
              <span>上午</span>
              <div class="t-times">
                <span v-for="(item, index) in appted.forenoonList" :key="index">
                  {{ item.hour }}:00
                </span>
              </div>
            </div>
            <div class="wrap-column">
              <span>下午</span>
              <div class="t-times">
                <span v-for="(item, index) in appted.afternoonList" :key="index">
                  {{ item.hour }}:00
                </span>
              </div>
            </div>
            <div class="wrap-column">
              <span>晚上</span>
              <div class="t-times">
                <span v-for="(item, index) in appted.nightList" :key="index">
                  {{ item.hour }}:00
                </span>
              </div>
            </div>
            <!--   <div class="wrap-btn">
               <div class="cancel-btn">取消</div>
               <div class="confirm-btn">保存</div>
             </div> -->
          </div>
          <!-- 未配置课程列表 -->
          <div class="wrap" v-show="buttonIndex == 2">
            <div class="wrap-column">
              <span>上午</span>
              <div class="t-times">
                <span
                  :class="{ 't-current': item.isSelect == 1 }"
                  @click="chooseForenoonDay(index, item)"
                  v-for="(item, index) in notAdd.forenoonList"
                  :key="index"
                >
                  {{ item.value }}:00
                </span>
              </div>
            </div>
            <div class="wrap-column">
              <span>下午</span>
              <div class="t-times">
                <span
                  :class="{ 't-current': item.isSelect == 1 }"
                  @click="chooseNoonDay(index, item)"
                  v-for="(item, index) in notAdd.afternoonList"
                  :key="index"
                >
                  {{ item.value }}:00
                </span>
              </div>
            </div>
            <div class="wrap-column">
              <span>晚上</span>
              <div class="t-times">
                <span
                  :class="{ 't-current': item.isSelect == 1 }"
                  @click="chooseNightDay(index, item)"
                  v-for="(item, index) in notAdd.nightList"
                  :key="index"
                >
                  {{ item.value }}:00
                </span>
              </div>
            </div>
            <div class="wrap-btn">
              <div class="cancel-btn">取消</div>
              <div class="confirm-btn" @click="setApptDateHour">保存</div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        class="dialog-container"
        width="60%"
        title="取消预约"
        :visible.sync="showCancel"
        append-to-body
      >
        <div class="container-wrap wrap-column-center">
          <el-input
            class="reasonInput"
            placeholder="请输入取消预约原因"
            prefix-icon="el-icon-search"
            v-model="cancelReason"
          ></el-input>
          <el-button @click="cancelAppt" type="primary">提交</el-button>
        </div>
      </el-dialog>
      <el-pagination
        v-show="tabIndex == 0"
        class="page mt50"
        prev-text="上一页"
        next-text="下一页"
        background
        layout="prev, pager, next"
        :total="totalPages"
        :page-size.sync="pageSize"
        @current-change="currentChange"
        :current-page.sync="pageIndex"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import renCalendar from '@/components/ren-calendar/ren-calendar.vue';
export default {
  name: '',
  data() {
    return {
      o2oCourses: [],
      keywords: '',
      courseName: '',
      apptList: [],
      date: [],
      apptDate: this.$.date.format(new Date(), 'yyyy-MM-dd'),
      courseId: '',
      endDay: '',
      startDay: '',
      // 关键字
      kw: '',
      status: '',
      activeName: 'second',
      courseStateIndex: 0,
      courseState: [
        { name: '全部', value: '' },
        { name: '未开始', value: '0' },
        { name: '进行中', value: '1' },
        { name: '已结束', value: '2' },
        { name: '学员旷课', value: '3' },
        { name: '老师旷课', value: '4' }
      ],
      buttonIndex: 0,
      buttonItems: ['可预约', '已预约', '未配置'],
      courseConfig: {
        0: '待上课',
        1: '上课中',
        2: '已上课',
        3: '学员旷课',
        4: '老师旷课',
        5: '学生已取消',
        6: '老师已取消'
      },
      // 是否有数据
      showNoData: false,
      // 展示取消预约遮罩状态
      showCancel: false,
      tabIndex: 0,
      tabList: ['已约课时管理', '未约课时管理'],
      // 总条数
      totalPages: 0,
      // 每页条数
      pageSize: 10,
      // 当前页码
      pageIndex: 1,
      apptIndex: 0,
      aldyApptList: [],
      appted: {
        forenoonList: [],
        afternoonList: [],
        nightList: []
      },
      notAppt: {
        forenoonList: [],
        afternoonList: [],
        nightList: []
      },
      notAdd: {
        forenoonList: [],
        afternoonList: [],
        nightList: []
      },
      // 时间表
      timeList: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
      forenoonIndex: -1,
      noonIndex: -1,
      nightIndex: -1,
      canApptHours: [],
      // 当前年月日
      curDate: this.$.date.format(new Date(), 'yyyy-MM-dd'),
      // 当前年月
      curYM: this.$.date.format(new Date(), 'yyyy-MM'),
      // 取消预约原因
      cancelReason: '',
      // 课程id
      lessonId: ''
    };
  },
  computed: {},
  created() {
    this.getAldyApptHours();
    this.getO2oCourses();
  },
  mounted() {},
  watch: {},
  methods: {
    // 当前页变化时执行
    currentChange(e) {
      this.pageIndex = e;
      this.getAldyApptHours();
    },
    // 更改 tab
    changeTab(index) {
      this.showNoData = false;
      this.tabIndex = index;
      this.pageIndex = 1;
      if (this.tabIndex == 0) {
        this.getAldyApptHours();
      } else if (this.tabIndex == 1) {
        this.getApptHours();
      }
    },
    // 更换按钮状态
    changeButton(index) {
      this.buttonIndex = index;
    },
    // 更改课程状态
    changeCourseState(index) {
      this.pageIndex = 1;
      this.courseStateIndex = index;
      this.status = this.courseState[index].value;
      this.getAldyApptHours();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 获取已约课时列表
    getAldyApptHours() {
      this.showNoData = false;
      this.post(
        `/biz/teacher/hour/aldyApptHours?pageNum=${this.pageIndex}&pageSize=${this.pageSize}`,
        { startDay: this.startDay, endDay: this.endDay, kw: this.kw, status: this.status },
        e => {
          if (e.code == 200) {
            this.aldyApptList = e.rows;
            this.totalPages = e.total;
            if (e.total == 0) {
              this.showNoData = true;
            }
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 获取未约课时列表
    getApptHours() {
      this.timeList = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
      // 清空上一次的数据
      this.appted.forenoonList = [];
      this.appted.afternoonList = [];
      this.appted.nightList = [];
      this.notAppt.forenoonList = [];
      this.notAppt.afternoonList = [];
      this.notAppt.nightList = [];
      this.notAdd.forenoonList = [];
      this.notAdd.afternoonList = [];
      this.notAdd.nightList = [];
	  if(this.courseId){
		this.post(
		  '/biz/teacher/hour/getApptDateHour',
		  { apptDate: this.apptDate, courseId: this.courseId },
		  e => {
		    if (e.code == 200) {
		      this.apptList = e.data;
		      console.log(this.apptList,'------------this.apptList--------------')
		      if (this.apptList.length > 0) {
		        this.apptList.forEach(item => {
		          // 已预约
		          if (item.status == 1) {
		            if (item.hour <= 12) {
		              this.appted.forenoonList.push(item);
		            } else if (item.hour <= 18) {
		              this.appted.afternoonList.push(item);
		            } else {
		              this.appted.nightList.push(item);
		            }
		          } else if (item.status == 0) {
		            // 可预约
		            if (item.hour <= 12) {
		              this.notAppt.forenoonList.push(item);
		            } else if (item.hour <= 18) {
		              this.notAppt.afternoonList.push(item);
		            } else {
		              this.notAppt.nightList.push(item);
		            }
		          }
		        });
		        console.log('this.timeList-------', this.timeList);
		        this.apptList.forEach(apptItem => {
		          let index = this.timeList.indexOf(apptItem.hour);
		          if (index !== -1) {
		            this.timeList.splice(index, 1);
		          }
		        });
		        console.log('this.timeList------', this.timeList);
		        this.timeList.forEach(item => {
		          if (item <= 12) {
		            this.notAdd.forenoonList.push({ value: item, isSelect: 0 });
		          } else if (item <= 18) {
		            this.notAdd.afternoonList.push({ value: item, isSelect: 0 });
		          } else {
		            this.notAdd.nightList.push({ value: item, isSelect: 0 });
		          }
		        });
		        console.log('this.notAdd', this.notAdd);
		      } else {
		        this.timeList.forEach(item => {
		          if (item <= 12) {
		            this.notAdd.forenoonList.push({ value: item, isSelect: 0 });
		          } else if (item <= 18) {
		            this.notAdd.afternoonList.push({ value: item, isSelect: 0 });
		          } else {
		            this.notAdd.nightList.push({ value: item, isSelect: 0 });
		          }
		        });
		      }
		      this.totalPages = e.total;
		      if (e.total == 0) {
		        this.showNoData = true;
		      }
		    } else {
		      this.$.ui.toast(e.msg);
		    }
		  }
		);  
	  }
      
    },
    // 取得老师的1对1课程列表
    getO2oCourses() {
      this.post('/biz/teacher/hour/getO2oCourses', {}, e => {
        if (e.code == 200) {
          this.o2oCourses = e.data;
          this.courseId = this.o2oCourses[0].id;
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 确定预约时间
    onfirmApptDate() {
      this.pageIndex = 1;
      this.getApptHours();
    },
    // 搜索
    onSearch() {
      this.pageIndex = 1;
      this.kw = this.keywords;
      if (this.tabIndex == 0) {
        this.getAldyApptHours();
      } else if (this.tabIndex == 1) {
        this.getApptHours();
      }
    },
    // 选择 o2o课程
    onChooseCourse(e) {
      this.courseId = e;
      this.getApptHours();
    },
    // 确认日期
    onfirmDate() {
      console.log(this.date);
      this.pageIndex = 1;
      if (this.date) {
        this.startDay = this.date[0];
        this.endDay = this.date[1];
      } else {
        this.startDay = '';
        this.endDay = '';
      }
      this.getAldyApptHours();
    },
    // 添加可预约日期到数组
    chooseForenoonDay(index, item) {
      if (item.isSelect == 1) {
        item.isSelect = 0;
        // 去重
        let idx = this.canApptHours.indexOf(item.value);
        this.canApptHours.splice(idx, 1);
      } else if (item.isSelect == 0) {
        item.isSelect = 1;
        this.canApptHours.push(item.value);
      }
      console.log('this.canApptHours', this.canApptHours);
    },
    chooseNoonDay(index, item) {
      if (item.isSelect == 1) {
        item.isSelect = 0;
        // 去重
        let idx = this.canApptHours.indexOf(item.value);
        this.canApptHours.splice(idx, 1);
      } else if (item.isSelect == 0) {
        item.isSelect = 1;
        this.canApptHours.push(item.value);
      }
      console.log('this.canApptHours', this.canApptHours);
    },
    chooseNightDay(index, item) {
      if (item.isSelect == 1) {
        item.isSelect = 0;
        // 去重
        let idx = this.canApptHours.indexOf(item.value);
        this.canApptHours.splice(idx, 1);
      } else if (item.isSelect == 0) {
        item.isSelect = 1;
        this.canApptHours.push(item.value);
      }
      console.log('this.canApptHours', this.canApptHours);
    },
    // 设置指定日期的可预约列表
    setApptDateHour() {
      this.post(
        '/biz/teacher/hour/setApptDateHour',
        { apptDate: this.apptDate, canApptHours: this.canApptHours, courseId: this.courseId },
        e => {
          if (e.code == 200) {
            if (e.data == true) {
              this.$.ui.toast('已添加到可预约列表');
              // TODO 刷新数据
              this.getApptHours();
              this.canApptHours = []
            }
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 展示取消预约
    showCancelAppt(item) {
      this.showCancel = true;
      this.lessonId = item.lessonId;
    },
    // 取消预约
    cancelAppt() {
      this.post(
        '/biz/teacher/hour/cancelAppt',
        { cancelReason: this.cancelReason, lessonId: this.lessonId },
        e => {
          if (e.code == 200) {
            if (e.data == true) {
              this.$.ui.toast('取消预约成功');
              this.cancelReason = '';
              this.showCancel = false;
              this.getAldyApptHours();
            }
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 标记老师或学生旷课
    absentAppt(item, type) {
      let text;
      if (type == 't') {
        text = '老师旷课';
      } else if (type == 's') {
        text = '学生旷课';
      }
      this.$confirm(`您确定标记为${text}吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.post('/biz/teacher/hour/absentAppt', { lessonId: item.lessonId, role: type }, e => {
            if (e.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              });
              this.getAldyApptHours();
            } else {
              this.$.ui.toast(e.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    }
  },
  components: {
    renCalendar
  }
};
</script>

<style lang="less">
.el-dialog {
  border-radius: 10px;
}
.dialog-container {
  /deep/ .el-dialog {
    border-radius: 10px !important;
  }
  .el-dialog__header {
    line-height: 30px;
    height: 30px;
    text-align: center;
    padding: 5px;
    background: #f5f5f7;
    border-radius: 10px 10px 0 0;
    .el-dialog__title {
      height: 20px;
      font-size: 14px;
      font-family: SF Pro Text;
      font-weight: 500;
      line-height: 20px;
      color: #45494d;
    }
  }
  .el-dialog__body {
    padding: unset;
    background: #ffffff;
    border-radius: 10px;
  }
  .el-dialog__body {
    border-radius: 10px;
  }
  .el-dialog__headerbtn {
    top: 15px;
  }
  .el-table {
    border-radius: 0 0 10px 10px;
    padding-left: 20px;
  }
}
</style>
