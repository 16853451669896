<template>
  <div class="calendar-wrapper">
    <div class="header" v-if="headerBar">
      <div class="pre" @click="changeMonth('pre')"></div>
      <div>{{ y + '年' + formatNum(m) + '月' }}</div>
      <div class="next" @click="changeMonth('next')"></div>
      <div class="back">返回今天</div>
    </div>
    <div class="week">
      <div class="week-day" v-for="(item, index) in weekDay" :key="index">{{ item }}</div>
    </div>
    <div :class="{ hide: !monthOpen }" class="content" :style="{ height: height }">
      <div :style="{ top: positionTop + 'px' }" class="days">
        <div class="item" v-for="(item, index) in dates" :key="index">
          <div
            class="day"
            @click="selectOne(item, $event)"
            :class="{
              choose: choose === `${item.year}-${item.month}-${item.date}` && item.isCurM,
              nolm: !item.isCurM,
              today: isToday(item.year, item.month, item.date),
              isWorkDay: isWorkDay(item.year, item.month, item.date),
              expire: isExprie(item.year, item.month, item.date) && item.isCurM,
              notStart: isNotStart(item.year, item.month, item.date) && item.isCurM
            }"
          >
            {{ Number(item.date) }}
          </div>
          <div
            class="markDay"
            v-if="isMarkDay(item.year, item.month, item.date) && item.isCurM"
          ></div>
<!--          <div class="today-text" v-if="isToday(item.year, item.month, item.date)">今</div> -->
        </div>
      </div>
    </div>
    <image
      src="https://i.loli.net/2020/07/16/2MmZsucVTlRjSwK.png"
      mode="scaleToFill"
      v-if="collapsible"
      @click="toggle"
      class="weektoggle"
      :class="{ down: monthOpen }"
    ></image>
  </div>
</template>

<script>
export default {
  name: 'ren-calendar',
  props: {
    // 星期几为第一天(0为星期日)
    weekstart: {
      type: Number,
      default: 0
    },
    // 标记的日期
    markDays: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 是否展示月份切换按钮
    headerBar: {
      type: Boolean,
      default: true
    },
    // 是否展开
    open: {
      type: Boolean,
      default: true
    },
    // 是否可收缩
    collapsible: {
      type: Boolean,
      default: true
    },
    // 未来日期是否不可点击
    disabledAfter: {
      type: Boolean,
      default: false
    },
    // 过期日期
    expire: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 未开始
    notStart: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      weektext: ['日', '一', '二', '三', '四', '五', '六'],
      y: new Date().getFullYear(), // 年
      m: new Date().getMonth() + 1, // 月
      dates: [], // 当前月的日期数据
      positionTop: 0,
      monthOpen: true,
      choose: ''
    }
  },
  created () {
    this.dates = this.monthDay(this.y, this.m)
    !this.open && this.toggle()
  },
  mounted () {
    this.choose = this.getToday().date
  },
  computed: {
    // 顶部星期栏
    weekDay () {
      return this.weektext.slice(this.weekstart).concat(this.weektext.slice(0, this.weekstart))
    },
    height () {
      return (this.dates.length / 7) * 80 + 'px'
    }
  },
  methods: {
    formatNum (num) {
      const res = Number(num)
      return res < 10 ? '0' + res : res
    },
    getToday () {
      const date = new Date()
      const y = date.getFullYear()
      const m = date.getMonth()
      const d = date.getDate()
      const week = new Date().getDay()
      const weekText = ['日', '一', '二', '三', '四', '五', '六']
      const formatWeek = '星期' + weekText[week]
      const today = {
        date: y + '-' + this.formatNum(m + 1) + '-' + this.formatNum(d),
        week: formatWeek
      }
      return today
    },
    // 获取当前月份数据
    monthDay (y, month) {
      const dates = []
      const m = Number(month)
      const firstDayOfMonth = new Date(y, m - 1, 1).getDay() // 当月第一天星期几
      const lastDateOfMonth = new Date(y, m, 0).getDate() // 当月最后一天
      const lastDayOfLastMonth = new Date(y, m - 2, 0).getDate() // 上一月的最后一天
      const weekstart = this.weekstart === 7 ? 0 : this.weekstart
      const startDay = (() => {
        // 周初有几天是上个月的
        if (firstDayOfMonth === weekstart) {
          return 0
        } else if (firstDayOfMonth > weekstart) {
          return firstDayOfMonth - weekstart
        } else {
          return 7 - weekstart + firstDayOfMonth
        }
      })()
      const endDay = 7 - ((startDay + lastDateOfMonth) % 7) // 结束还有几天是下个月的
      for (let i = 1; i <= startDay; i++) {
        dates.push({
          date: this.formatNum(lastDayOfLastMonth - startDay + i),
          day: weekstart + i - 1 || 7,
          month: m - 1 >= 0 ? this.formatNum(m - 1) : 12,
          year: m - 1 >= 0 ? y : y - 1
        })
      }
      for (let j = 1; j <= lastDateOfMonth; j++) {
        dates.push({
          date: this.formatNum(j),
          day: (j % 7) + firstDayOfMonth - 1 || 7,
          month: this.formatNum(m),
          year: y,
          isCurM: true // 是否当前月份
        })
      }
      for (let k = 1; k <= endDay; k++) {
        dates.push({
          date: this.formatNum(k),
          day: (lastDateOfMonth + startDay + weekstart + k - 1) % 7 || 7,
          month: m + 1 <= 11 ? this.formatNum(m + 1) : 0,
          year: m + 1 <= 11 ? y : y + 1
        })
      }
      // console.log(dates);
      return dates
    },
    isWorkDay (y, m, d) {
      // 是否工作日
      const ymd = `${y}/${m}/${d}`
      const formatDY = new Date(ymd.replace(/-/g, '/'))
      const week = formatDY.getDay()
      if (week === 0 || week === 6) {
        return false
      } else {
        return true
      }
    },
    isFutureDay (y, m, d) {
      // 是否未来日期
      const ymd = `${y}/${m}/${d}`
      const formatDY = new Date(ymd.replace(/-/g, '/'))
      const showTime = formatDY.getTime()
      const curTime = new Date().getTime()
      if (showTime > curTime) {
        return true
      } else {
        return false
      }
    },
    // 标记日期
    isMarkDay (y, m, d) {
      let flag = false
      for (let i = 0; i < this.markDays.length; i++) {
        const dy = `${y}-${m}-${d}`
        if (this.markDays[i] === dy) {
          flag = true
          break
        }
      }
      return flag
    },
    // 是否为今天
    isToday (y, m, d) {
      const checkD = y + '-' + m + '-' + d
      const today = this.getToday().date
      if (checkD === today) {
        return true
      } else {
        return false
      }
    },
    // 课程日期是否过期
    isExprie (y, m, d) {
      let flag = false
      const day = `${y}-${m}-${d}`
      for (let i = 0; i < this.expire.length; i++) {
        if (this.expire[i] === day) {
          flag = true
          break
        }
      }
      return flag
    },
    // 未开始的课程
    isNotStart (y, m, d) {
      let flag = false
      const day = `${y}-${m}-${d}`
      for (let i = 0; i < this.notStart.length; i++) {
        if (this.notStart[i] === day) {
          flag = true
          break
        }
      }
      return flag
    },
    // 展开收起
    toggle () {
      this.monthOpen = !this.monthOpen
      if (this.monthOpen) {
        this.positionTop = 0
      } else {
        let index = -1
        this.dates.forEach((i, x) => {
          this.isToday(i.year, i.month, i.date) && (index = x)
        })
        this.positionTop = -((Math.ceil((index + 1) / 7) || 1) - 1) * 80
      }
    },
    // 点击回调
    selectOne (i, event) {
      const date = `${i.year}-${i.month}-${i.date}`
      const selectD = new Date(date).getTime()
      const curTime = new Date().getTime()
      const week = new Date(date).getDay()
      const weekText = ['日', '一', '二', '三', '四', '五', '六']
      const formatWeek = '星期' + weekText[week]
      const response = {
        date: date,
        week: formatWeek
      }
      if (!i.isCurM) {
        // console.log('不在当前月范围内');
        return false
      }
      if (selectD > curTime) {
        if (this.disabledAfter) {
          console.log('未来日期不可选')
          return false
        } else {
          this.choose = date
          this.$emit('onDayClick', response)
        }
      } else {
        this.choose = date
        this.$emit('onDayClick', response)
      }
    },
    // 改变年月
    changYearMonth (y, m) {
      this.dates = this.monthDay(y, m)
      this.y = y
      this.m = m
    },
    changeMonth (type) {
      if (type === 'pre') {
        if (this.m + 1 === 2) {
          this.m = 12
          this.y = this.y - 1
        } else {
          this.m = this.m - 1
        }
      } else {
        if (this.m + 1 === 13) {
          this.m = 1
          this.y = this.y + 1
        } else {
          this.m = this.m + 1
        }
      }
      this.dates = this.monthDay(this.y, this.m)
      const date = this.y + '-' + this.formatNum(this.m)
      this.$emit('onChangeYM', date)
    }
  }
}
</script>

<style lang="less" scoped>
.calendar-wrapper {
  padding: 20px;
  width: 486px;
  font-family: SF Pro Text;
  background-color: #fff;
  box-shadow: 0px 5px 5px rgba(247, 247, 247, 0.75);
  border-radius: 20px;
  // color: #bbb7b7;
  font-size: 20px;
  text-align: center;
  div {
    cursor: pointer;
  }
  .header {
    position: relative;
    font-family: SF Pro Text;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: bold;
    color: #110e0e;
    display: flex;
    align-items: center;
    justify-content: center;
    .pre {
      text-align: center;
      width: 15px;
      height: 15px;
      margin-right: 15px;
      background: url(~@/assets/left-arrow.png) no-repeat center center;
      background-size: 8.8px 14.8px;
    }
    .next {
      text-align: center;
      width: 15px;
      height: 15px;
      margin-left: 15px;
      background: url(~@/assets/right-arrow.png) no-repeat center center;
      background-size: 8.8px 14.8px;
    }
    .back {
      position: absolute;
      top: center;
      right: 0;
      width: 68px;
      height: 30px;
      background: #f5f5f7;
      border-radius: 5px;
      font-size: 12px;
      line-height: 30px;
      color: #89898c;
    }
  }

  .week {
    display: flex;
    align-items: center;
    .week-day {
      width: 56px;
      line-height: 44px;
      height: 44px;
      font-size: 13px;
      font-family: SF Pro Text;
      color: #323233;
    }
    div {
      cursor: pointer;
      flex: 1;
    }
  }

  .content {
    position: relative;
    overflow: hidden;
    transition: height 0.4s ease;
    .days {
      transition: top 0.3s;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      .item {
        margin-bottom: 4px;
        // padding-bottom: 4px;
        position: relative;
        display: block;
        height: 56px;
        line-height: 44px;
        width: calc(100% / 7);
        .day {
          font-style: normal;
          display: inline-block;
          vertical-align: middle;
          box-sizing: border-box;
          width: 56px;
          height: 56px;
          font-size: 20px;
          line-height: 35px;
          font-size: 20px;
          font-weight: 500;
          color: #323233;
          overflow: hidden;
          border-radius: 5px;
          font-weight: bold;
          margin-bottom: 4px;
          &.choose {
            font-style: normal;
            display: inline-block;
            vertical-align: middle;
            box-sizing: border-box;
            box-sizing: border-box;
            width: 56px;
            height: 56px;
            font-size: 20px;
            line-height: 35px;
            font-size: 20px;
            font-weight: 500;
            overflow: hidden;
            border-radius: 5px;
            font-weight: bold;
            margin-bottom: 4px;
            background-color: #4d7df9;
            color: #fff;
          }
          &.haveClass {
            background-color: #007bff;
            color: #fff;
          }

          &.nolm {
            color: #fff;
            opacity: 0.3;
          }
        }

        .isWorkDay {
          color: #42464a;
        }

        .notSigned {
          font-style: normal;
          width: 8px;
          height: 8px;
          background: #fa7268;
          border-radius: 10px;
          position: absolute;
          left: 50%;
          bottom: 0;
          pointer-events: none;
        }

        .today {
          position: relative;
          color: #fff;
          background-color: #a8c0ff;
        }
        .today:before {
          position: absolute;
          top: 2px;
          left: 2px;
          content: '今';
          width: 11px;
          height: 16px;
          font-size: 11px;
          font-family: SF Pro Text;
          font-weight: 500;
          line-height: 13px;
          // color: #e1e1e6;
        }

        // .workDay {
        //   font-style: normal;
        //   width: 8px;
        //   height: 8px;
        //   background: #4d7df9;
        //   border-radius: 10px;
        //   position: absolute;
        //   left: 50%;
        //   bottom: 0;
        //   pointer-events: none;
        // }

        // .markDay {
        //   font-style: normal;
        //   width: 8px;
        //   height: 8px;
        //   background: #fc7a64;
        //   border-radius: 10px;
        //   position: absolute;
        //   left: 50%;
        //   bottom: 0;
        //   pointer-events: none;
        // }

        .expire {
          font-style: normal;
          display: inline-block;
          vertical-align: middle;
          width: 56px;
          height: 56px;
          font-size: 20px;
          font-weight: 500;
          line-height: 38px;
          overflow: hidden;
          color: #323233;
          overflow: hidden;
          border-radius: 5px;
          font-weight: bold;
          background: #f5f5f7;
        }
        .expire::after {
          content: '过期';
          text-align: center;
          width: 56px;
          height: 17px;
          font-size: 12px;
          line-height: 14px;
          // color: #89898c;
          display: block;
        }
        .notStart {
          font-style: normal;
          display: inline-block;
          vertical-align: middle;
          box-sizing: border-box;
          width: 56px;
          height: 56px;
          font-size: 20px;
          line-height: 35px;
          overflow: hidden;
          color: #323233;
          overflow: hidden;
          border-radius: 5px;
          font-weight: bold;
          background: #ffffff;
          border: 2px solid #007bff;
          color: #007bff;
        }
        .notStart::after {
          content: '有课';
          text-align: center;
          width: 56px;
          height: 17px;
          font-size: 12px;
          line-height: 14px;
          // color: #89898c;
          display: block;
        }
      }
    }
  }

  .hide {
    height: 80px !important;
  }

  .weektoggle {
    width: 85px;
    height: 32px;
    position: relative;
    bottom: -10px;

    &.down {
      transform: rotate(180deg);
      bottom: 0;
    }
  }
}
</style>
